html {
  display: flex;
  font-size: rem-calc(16px);
}

body {
  font-family: $font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-flow: column nowrap;
  line-height: 26px;
  min-height: 100vh;
  width: 100%;
  transition: all 0.3s ease;
  position: relative;
  left: 0;
  overflow-x: hidden;

  > form#form {
    display: flex;
    flex-flow: column nowrap;
    min-height: 100vh;
  }
}

header, footer {
  flex-shrink: 0;
}

main {
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  font-size: rem-calc(18px);
  margin: $s-space 0 $l-space;
  position: relative;
}
