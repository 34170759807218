.c-nav {
  animation: slide-out .35s ease-in-out;
  background: $color-gray-dark;
  height: calc(100vh - #{$navigation-height});
  padding-bottom: $s-space;
  position: absolute;
  right: -#{$navigation-width};
  top: $navigation-height;
  transform-origin: center right;
  transition: all .35s ease-in-out;
  width: 0;
  z-index: 10;

  ul {
    display: none;
    padding-left: 0;
  }

  &.active {
    animation: slide-in .35s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    right: 0;
    width: $navigation-width;

    @media screen and (max-width: $mobile-width) {
      width: $navigation-width-mobile;
    }

    ul {
      display: block;

      &.c-social-icons {
        display: flex;
      }
    }
  }

  li {
    list-style: none;
  }

  &-primary,
  &-secondary {
    padding-top: 75px;

    @media screen and (max-width: $tablet-width) {
      padding-top: $l-space;
    }

    button,
    a {
      @include flex;
      align-items: center;
      min-height: 35px;
      padding-left: 75px;
      text-decoration: none;
      width: 100%;

      &:hover,
      &:focus {
        background: $color-gray-darker;
      }
    }
  }

  &-primary {
    &-category {
      padding-left: 75px;
      position: relative;
      text-align: left;

      &::before {
        border-color: transparent transparent $white;
        border-style: solid;
        border-width: 0 5px 5px;
        content: '';
        left: 50px;
        position: absolute;
        top: calc(50% - 2.5px);
        transform: rotate(90deg);
        transition: transform .3s ease;
      }

      &[aria-expanded='true'] {
        &::before {
          transform: rotate(180deg);
        }
      }
    }
  }

  &-submenu {
    height: 0;
    overflow: hidden;
    transition: all .3s ease;

    a {
      display: none;
      padding: 0 0 0 7.5rem;
    }

    &.active {
      height: auto;

      a {
        @include flex;
      }
    }
  }

  &-close {
    height: 40px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 40px;

    .one,
    .two {
      background-color: $white;
      display: block;
      height: 2px;
      left: 11px;
      position: absolute;
      top: 18px;
      width: 22px;
      z-index: 1;
    }

    .one {
      transform: rotate(45deg);
    }

    .two {
      transform: rotate(-45deg);
    }
  }
}
