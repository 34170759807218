ul,
ol {
  padding-left: $s-space;
  li {

    ul,
    ol {
      padding-left: $s-space;
    }
  }
}

ol {
  // Default list
  counter-reset: number;
  list-style-type: none;

  li {
    list-style-type: none;
    
    &::before {
      content: counter(number)"\a0\a0";
      counter-increment: number;
      margin-left: -20px;
    }
  }

  // Uppercase List
  &.A[type="A"] {
    counter-reset: sectionA;
  
    li {
  
      &::before {
        content: counter(sectionA, upper-alpha)"\a0\a0";
        counter-increment: sectionA;
      }
  
      ol {
        counter-reset: sectionA;
      }
    }
  }

  // Lowercase List
  &.a[type="a"] {
    counter-reset: sectionB;
  
    li {
  
      &::before {
        content: counter(sectionB, lower-alpha)"\a0\a0";
        counter-increment: sectionB;
      }
  
      ol {
        counter-reset: sectionB;
      }
    }
  }

  &.i[type="i"] {
    counter-reset: sectionC;
  
    li {
  
      &::before {
        content: counter(sectionC, lower-roman)"\a0\a0";
        counter-increment: sectionC;
      }
  
      ol {
        counter-reset: sectionC;
      }
    }
  }

  &.I[type="I"] {
    counter-reset: sectionD;
  
    li {
  
      &::before {
        content: counter(sectionD, upper-roman)"\a0\a0";
        counter-increment: sectionD;
      }
  
      ol {
        counter-reset: sectionD;
      }
    }
  }
}
