.c-article {
  padding-right: $l-space;

  @media screen and (max-width: $tablet-width) {
    padding-right: 0;
  }

  &-body {
    padding-left: $l-space;

    @media screen and (max-width: $tablet-width) {
      padding-left: 0;
    }

    &-content {
      margin: $l-space 0;
      font-family: $font-article-body;
      font-size: rem-calc(16px);
      line-height: 22px;
      width: 100%;

      .c-article-image {
        margin: $l-space 0 $l-space -#{$l-space};

        img {
          width: calc(100% + 50px);
        }

        @media screen and (max-width: $tablet-width) {
          margin: $s-space 0;

          img {
            width: 100%;
          }
        }
      }

      ul,
      ol {
        margin-top: $l-space;
        margin-bottom: $l-space;

        ul,
        ol {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  &-intro {
    margin-bottom: $m-space;

    .c-article-author {
      display: block;
      margin-bottom: $m-space;
    }
  }

  &-title {
    display: block;

    .heading-large {
      font-size: rem-calc(24px);
      line-height: 30px;
    }
  }

  &-author {
    color: $color-gray;
    font-family: $font-category;
    font-size: rem-calc(12);
    text-transform: uppercase;

    &-name {
      color: $color-link;
      font-family: $font-author-name;
      font-size: rem-calc(16px);
      line-height: 22px;

      &::before {
        background: $color-link;
      }

      &:hover,
      &:focus {
        color: $color-link-hover;
        text-decoration: none;
      }
    }

    &-bio {
      font-size: rem-calc(16px);
      margin: $l-space 0 $xl-space;
    }
  }

  &-date {
    font-family: $font-author-name;
    font-size: rem-calc(16);
    line-height: 22px;
  }

  &-image {
    margin-bottom: $m-space;

    figcaption {
      color: $color-gray;
      font-size: rem-calc(14);
      line-height: 18px;
    }
  }

  .c-social-bar {
    @include border-bars;
    color: $color-gray;
    font-family: $font-author-name;
    margin-bottom: $l-space;

    a {
      svg {
        fill: $color-link;
      }

      &:hover,
      &:focus {
        svg {
          fill: $color-black;
        }
      }
    }
  }
}
