blockquote {
  @include border-bars;
  color: $color-gray;
  font-family: $font-header-large;
  font-size: rem-calc(28);
  line-height: 34px;
  margin: $l-space 0;
  max-width: 100%;
  padding: $l-space 0;
  text-align: left;
  width: 570px;

  p {
    font-family: $font-header-large;
    font-size: rem-calc(28);
    line-height: 34px;
  }
}
