.c-404 {
  align-items: center;
  display: flex;
  justify-content: center;

  &-container {
    height: 60vh;
    justify-content: space-around;
  }

  img,
  svg {
    width: 50%;
  }

  svg {
    margin-bottom: $l-space;
  }
}
