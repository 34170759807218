@keyframes grow-down {
  from {
    height: 0;
  }

  to {
    height: $navigation-height;
  }
}

@keyframes grow-up {
  from {
    height: $navigation-height;
  }

  to {
    height: 0;
  }
}

@keyframes slide-in {
  from {
    right: -#{$navigation-width};
  }

  to {
    right: 0;
  }
}

@keyframes slide-out {
  from {
    right: 0;
  }

  to {
    right: -#{$navigation-width};
  }
}

@keyframes rotate {
  0% { }
  70% { transform: rotate(250deg);}
  100% { transform: rotate(360deg); }
}

@keyframes resize {
  40% { transform: scale(1); }
  70% { transform: scale(1.1); }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
