.o-page-title {
  @include flex-center;
  margin-bottom: $l-space;
  text-transform: uppercase;
  
  .heading-large {
    @media screen and (max-width: $tablet-width) {
      font-size: rem-calc(26px);
      line-height: 34px;
    }
  }
}
