button {
  background: $color-gray;
  border: 0;
  padding: 0;
}

.button-round {
  background: $color-gray;
  border-radius: 25px;
  color: $color-white;
  display: inline-block;
  font-size: rem-calc(16px);
  margin: auto;
  padding: $xs-space $m-space;
  text-align: center;
  text-decoration: none;
  width: auto;

  &:hover,
  &:focus {
    background: $color-gray-dark;
    color: $color-white;
  }
}
