.c-header {
  @include flex;
  background: $black;
  color: $white;
  height: $navigation-height;
  justify-content: center;
  z-index: $z-mid;

  &-container {
    @include flex;
    justify-content: space-between;
    position: relative;
    max-width: 1280px;
    width: 100%;

    @media screen and (max-width: $mobile-width) {
      justify-content: center;
    }
  }

  .mobile-option {
    display: flex;
  }

  &-items {
    @include flex-row;
  }

  &-item {
    @include flex-row;
    padding: 0 15px 0 0;
    
    &.divider::before {
      background: $color-gray-dark;
      height: 16px;
      top: calc(50% - 8px);
    }

    a,
    button {
      background: none;
      color: $white;
      font-family: $font-author-name;
      font-size: rem-calc(18);
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &-icon {
    &-small {
      @include flex-center;
      img {
        width: 155px;
      }
    }

    &-large {
      @include flex-center;
      img {
        width: 200px;

        @media screen and (max-width: $mobile-width) {
          width: 150px;
        }
      }
    }
  }

  &-hamburger {
    height: 40px;
    opacity: 1;
    position: relative;
    width: 40px;

    @media screen and (max-width: $mobile-width) {
      position: absolute;
      right: 20px;
    }

    .one,
    .two,
    .three {
      display: block;
      width: 22px;
      height: 2px;
      position: absolute;
      top: 40%;
      left: 9px;
      z-index: 1;
      background-color: $white;
      transition: all 0.3s ease;
    }
    .one {
      top: 10px;
    }
    .two {
      top: 17px;
    }
    .three {
      top: 24px;
    }

    &.active {
      .one {
        top: 18px;
        left: 11px;
        transform: rotate(45deg);
      }
      .two {
        opacity: 0;
      }
      .three {
        top: 18px;
        left: 11px;
        transform: rotate(-45deg);
      }
    }
  }

  a,
  button {
    background: none;
    color: $white;
    font-size: rem-calc(18);
  }

  @media screen and (max-width: $mobile-width) {
    .mobile-option {
      display: none;
      left: -30px;
      position: absolute;
      transition: left 3s ease-in;
    }
  }
}

.nav-active {
  @media screen and (max-width: $mobile-width) {
    .c-header {
      &-items {
        img {
          display: none;
        }
      }

      .mobile-option {
        display: flex;
        left: $s-space;
      }
    }
  }
}

