.heading-large {
  font-family: $font-header-large;
  font-size: rem-calc(34px);
  line-height: 44px;
  max-width: 100%;

  @media screen and (max-width: $mobile-width) {
    font-size: rem-calc(26px);
    line-height: 34px;
  }
}

.article-category {
  color: $color-link;
  display: inline-block;
  font-family: $font-category;
  font-size: rem-calc(14);
  text-decoration: none;
}

p.article-category {
  margin-bottom: 0;
}

a.article-category {
  &:hover,
  &:focus {
    color: $color-link-hover;
    text-decoration: underline;
  }
}

.article-title,
a.article-title,
h3 {
  color: $color-black;
  font-family: $font-article-title;
  font-size: rem-calc(22);
  line-height: 28px;
  text-decoration: none;

  &a,
  a {
    color: $color-black;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

h3.article-title {
  margin-bottom: 0;
}

.section-title {
  color: $color-gray;
  font-family: $font-header-large;
  font-size: rem-calc(22);
  line-height: 28px;
  margin-bottom: 0;
}

.article-block-text {
  color: $color-gray;
  font-family: $font-author-name;
  font-size: rem-calc(16);
  margin-bottom: 0;

  a {
    color: $color-link;

    &:hover,
    &:focus {
      color: $color-link-hover;
      text-decoration: none;
    }
  }
}

.events-details-text {
  color: $color-link;
  font-family: $font-author-name;
  font-size: rem-calc(16px);
  line-height: 22px;

  a {
    &:hover,
    &:focus {
      color: $color-link-hover;
      text-decoration: none;
  }
  }
}
