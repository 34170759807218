// SETTINGS
@import 'settings/fonts';
@import 'settings/settings';
@import '_foundation/foundation';

// TOOLS
@import 'tools/mixins';
@import 'tools/functions';
@import 'tools/placeholders';
@import 'tools/animation';

// GENERIC
@import 'generic/normalize';
@import 'generic/reset';
@import 'generic/global';

// ELEMENTS
@import 'elements/blockquote';
@import 'elements/figure';
@import 'elements/buttons';
@import 'elements/headings';
@import 'elements/inputs';
@import 'elements/links';
@import 'elements/lists';
@import 'elements/paragraph';
@import 'elements/tables';

// OBJECTS
@import 'objects/container';
@import 'objects/fonts';
@import 'objects/grid';
@import 'objects/helper';
@import 'objects/page';

//COMPONENTS
@import 'components-critical/404';
@import 'components-critical/article';
@import 'components-critical/blazy';
@import 'components-critical/header';
@import 'components-critical/loader';
@import 'components-critical/featured-article';
@import 'components-critical/navigation';
@import 'components-critical/section-title';
@import 'components-critical/social-bar';

// TRUMPS
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-form-field-error;
@include foundation-form-submit-btn;
@import 'trumps/helper';

// SCOPES
.defer {
  opacity: 0;
  transition: opacity .3s ease;
}
