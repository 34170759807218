a.skip-to-content {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  text-align: center;
  padding: 10px;
  position: absolute;
  top: 10px;
  color: #fff;
  background: black;
  display: block;
  width: auto;

  &:focus {
    position: static !important;
    height: auto;
    width: auto;
    overflow: visible;
    clip: auto;
    position: absolute !important;
    z-index: 1000;
  }

  &:visited {
    color: #fff;
  }
}

.visually-hidden {
  position: absolute !important;
  height: 1px; width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.divider {
  padding: 0 15px 0 0;
  position: relative;

  &::before {
    content: '';
    background: $color-gray-light;
    height: 10px;
    position: absolute;
    right: 7px;
    top: calc(50% - 5px);
    width: 1px;
  }
}