p {
  font-size: rem-calc(16);
  line-height: 22px;
  margin-bottom: $xs-space;

  a {
    color: $color-link-hover;

    &:hover,
    &:focus {
      color: $color-link;
    }
  }
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

sub,
sup {
  font-size: .75em;
}
