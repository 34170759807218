.o {
  &-container {
    display: flex;
    flex-direction: column;
    min-height: 1px;

    &-feature {
      flex-grow: 1;
      width: 100%;
    }

    &-item {
      flex-grow: 1;
      width: auto;
    }

    &-main {
      width: 437px;
    }

    &-aside {
      flex-grow: 1;
      width: 100%;
    }
  }
}

.flex-container {
  &-column {
    @include flex-column;
  }
}