.c-loader {
  @include flex-center;
  height: 100px;
  width: 100%;

  .circle {
    animation: resize 1s linear infinite;
    background: transparent;
    border-radius: 50%;
    height: 50px;
    position: relative;
    width: 50px;

    &::after {
      animation: rotate 1s linear infinite;
      border: 10px solid transparent;
      border-radius: 50%;
      border-top-color: $color-black;
      content: '';
      display: block;
      height: 100px;
      left: -10px;
      position: absolute;
      top: -10px;
      width: 100px;
    }
  }
}
