table {
  caption,
  th {
    font-family: $font-category;
  }

  th,
  td {
    border: 1px solid $color-gray;
    padding: $xs-space;
  }

  th {
    background: $color-black;
    color: $color-white;
  }

  td {
    font-family: $font-primary;
  }
}
