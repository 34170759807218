.c-social {
  &-bar {
    @include flex;
    align-items: center;
    height: 50px;
    font-size: rem-calc(16px);

    @media screen and (max-width: $tablet-width) {
      margin-bottom: $m-space;
      width: 100%;
    }

    a {
      @include flex-center;
      margin-right: $s-space;
      text-decoration: none;

      &:last-of-type {
        margin-right: 0;
      }
    }

    li {
      list-style-type: none;
      margin-right: $s-space;
    }

    svg {
      fill: $color-black;
      height: 20px;
      max-width: 20px;
      
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        width: 20px;
     }

      &:hover,
      &:focus {
        fill: $color-gray;
      }
    }
  }

  &-icons {
    @include flex;
    align-items: center;

    @media screen and (max-width: $tablet-width) and (min-width: $mobile-width) {
      padding-left: $m-space;
    }

    @media screen and (max-width: $mobile-width) {
      padding-left: $xxs-space;
    }
  }
}

nav {
  .c-social-bar {
    margin-top: $l-space;
    min-height: 50px;
    padding-left: 75px;

    @media screen and (max-width: $tablet-width) {
      margin-bottom: 0;
    }

    a {

      svg {
        fill: $color-white;
      }

      &:hover,
      &:focus {

        svg {
          fill: $color-gray;
        }
      }
    }
  }
}

.c-footer {
  .c-social-bar {
    a {
      &:hover,
      &:focus {

        svg {
          fill: $color-gray-light;
        }
      }
    }

    svg {
      fill: $color-white;
    }

    .c-social-icons {
      padding-left: 0;
      width: 100%;
    }
  }
}

.c-article-body {
  .c-social-bar {
    @media screen and (max-width: $tablet-width) {
      margin-bottom: 0;
    }
  }

  .c-social-icons {
    padding-left: $m-space;
  }
}
