.c-featured {

  .heading-large {
    @extend h1;
    color: $color-link-hover;
    margin-bottom: 0;
    text-decoration: none;

    @media screen and (max-width: $mobile-width) {
      font-size: rem-calc(24px);
      line-height: 30px;
      margin-bottom: 0;
    }

    &:hover,
    &:focus {
      color: $color-link;
      text-decoration: underline;
    }
  }

  &-article {
    @include flex-column;
    align-items: center;
    background: $color-gray-lighter;
    justify-content: center;
    margin-bottom: $m-space;
    margin-top: -#{$s-space};
    width: 100%;

    &-card {
      @include flex-column;
      align-items: center;
      background: $color-white;
      justify-content: space-between;
      margin: auto;
      padding: $l-space $l-space;
      position: relative;
      text-align: center;
      top: -100px;
      width: 724px;

      @media screen and (max-width: $tablet-width) {
        width: 85%;
      }

      @media screen and (max-width: $mobile-width) {
        padding: $m-space $s-space;
        top: -60px;
      }

      p {
        max-width: 100%;
      }
    }

    &-list {
      padding-bottom: $l-space;
      position: relative;
      width: 100%;

      &::before {
        background-color: $color-gray-light;
        bottom: 0;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        width: 100%;

        @media screen and (min-width: $tablet-width) {
          left: 16px;
          width: calc(100% - 32px);
        }
      }

      @media screen and (max-width: $tablet-width) {
        flex-direction: column-reverse;
      }
    }

    &-image {
      max-height: 85vh;
      width: 100%;

      &-list {
        @media screen and (min-width: $tablet-width) {
          @include flex;
          flex-direction: row-reverse;
        }
      }
    }

    &-title {
      &-list {
        margin-bottom: $m-space;
      }
    }

    &-content {
      &-list {
        @include flex-column;
        justify-content: center;
      }
    }
  }
}
