h1,
h2,
h3 {
  font-family: $font-header-large;
  margin-bottom: 1rem;
}

h4,
h5,
h6 {
  font-family: $font-category;
}

h1 {
  color: $color-gray;
  font-size: rem-calc(34px);
  line-height: 42px;

  @media screen and (max-width: $mobile-width) {
    font-size: rem-calc(26px);
    line-height: 34px;
  }
}

h2 {
  font-size: rem-calc(28px);
  line-height: 34px;
}

h3 {
  font-size: rem-calc(20px);
  line-height: 26px;
}

h4,
h5,
h6 {
  font-size: rem-calc(16px);
  line-height: 22px;
}
