@mixin field-error {
    color: $color-red;
    display: block;
    padding-bottom: 10px;
    text-align: left;
}

@mixin foundation-form-field-error {
    .field-validation-error {
        @include field-error;
    }
}