.o-grid {
  &-wrap {
    flex-wrap: wrap;
  }
  &-wrapper {
    @include flex;
    flex-wrap: wrap;
    max-width: 1280px;
    width: 100%;

    &:last-child {
      margin: 0;
    }

    @media screen and (max-width: $tablet-width) {
      margin: 0;
      padding: 0 $s-space;
      width: 100%;
    }
  }

  &-column {
    &-1 {
      width: 16.6%;
      padding: 0 16px;
      @media screen and (max-width: $tablet-width) {
        width: 100%;
        padding: 0;
      }
    }

    &-2 {
      width: 33.3%;
      padding: 0 16px;
      @media screen and (max-width: $tablet-width) {
        width: 100%;
        padding: 0;
        
      }
    }

    &-3 {
      width: 50%;
      padding: 0 16px;
      @media screen and (max-width: $tablet-width) {
        width: 100%;
        padding: 0;
      }
    }

    &-4 {
      width: 66.6%;
      padding: 0 16px;
      @media screen and (max-width: $tablet-width) {
        width: 100%;
        padding: 0;
        
      }
    }

    &-5 {
      width: 79.9%;
      padding: 0 16px;
      flex-grow: 1;

      @media screen and (max-width: $tablet-width) {
        width: 100%;
        padding: 0;
        
      }
    }

    &-6 {
      width: 100%;
      padding: 0 16px;
      @media screen and (max-width: $tablet-width) {
        padding: 0;
      }
    }
  }
}
